.activeNavBar{
    border-bottom: 5px solid #F78D2A !important;
    svg path {
        fill: #F78D2A 
    }
    color:  #F78D2A,
}

.hoverIcon:hover{
    svg path {
        fill: #F78D2A 
    }
}